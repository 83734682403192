import Loadable from "../components/Loadable";

import PengaturanSistem from "../views/Karyawan/Sistem/Index";
import TataTertib from "../views/Karyawan/TataTertib/Index";
import Nilai from "../views/Karyawan/Nilai/Index";
import Prestasi from "../views/Karyawan/Prestasi/Index";
import Kelas from "../views/Karyawan/Kelas/Index";
import Ekstrakurikuler from "../views/Karyawan/Ekstrakurikuler/Index";
import Rapor from "../views/Karyawan/Rapor/Index";
import Kurikulum from "../views/Karyawan/Kurikulum/Index";
import Administrasi from "../views/Karyawan/Administrasi/Index";
import PPDB from "../views/Karyawan/PPDB/Index";
import Konseling from "../views/Karyawan/Konseling/Index";
import Murid from "../views/Karyawan/Murid/Index";
import Pengumuman from "../views/Karyawan/Pengumuman/Index";
import PenilaianP5 from "../views/Karyawan/PenilaianP5/index";

const Profil = Loadable(() => import("../views/Karyawan/Profil"));

export default [
  {
    path: ["/pengumuman"].join("/"),
    component: Pengumuman.DaftarPengumumanView,
  },
  {
    path: ["/pengumuman", ":id"].join("/"),
    component: Pengumuman.DetilPengumumanView,
  },

  { path: ["/karyawan", "profil"].join("/"), component: Profil },

  {
    path: ["/kurikulum-k13", "daftar-mapel"].join("/"),
    component: Kurikulum.DaftarMapel,
  },
  {
    path: ["/kurikulum-k13", "daftar-mapel", ":id"].join("/"),
    component: Kurikulum.DetilDaftarMapel,
  },
  {
    path: ["/kurikulum-k13", "beban-mapel"].join("/"),
    component: Kurikulum.BebanMapel,
  },
  {
    path: ["/kurikulum-k13", "parameter-sikap"].join("/"),
    component: Kurikulum.ParameterSikap,
  },

  {
    path: ["/kurikulum-merdeka", "daftar-mapel"].join("/"),
    component: Kurikulum.DaftarMapel,
  },
  {
    path: ["/kurikulum-merdeka", "daftar-mapel", ":id"].join("/"),
    component: Kurikulum.DetilDaftarMapel,
  },
  {
    path: ["/kurikulum-merdeka", "proyek-p5"].join("/"),
    component: PenilaianP5.DaftarPengaturanProyek,
  },
  {
    path: ["/kurikulum-merdeka", "proyek-p5", "tambah"].join("/"),
    component: PenilaianP5.TambahPengaturanProyek,
  },
  {
    path: ["/kurikulum-merdeka", "proyek-p5", ":proyek_id"].join("/"),
    component: PenilaianP5.DetilPengaturanProyek,
  },
  {
    path: ["/kurikulum-merdeka", "pengaturan-p5"].join("/"),
    component: PenilaianP5.PengaturanProfil,
  },

  { path: ["/nilai", "daftar-nilai"].join("/"), component: Nilai.DaftarKelas },
  // { path: ['/nilai', 'absensi'].join('/'), component: Nilai.Absensi },
  // { path: ['/nilai', 'absensi',"tambah", "aa"].join('/'), component: Nilai.InputAbsensi },
  // { path: ['/nilai', 'absensi', "aa"].join('/'), component: Nilai.DetilHistoryAbsensi },
  {
    path: ["/nilai", "daftar-nilai", ":kbm_id"].join("/"),
    component: Nilai.DaftarNilai,
  },
  {
    path: ["/nilai", "daftar-kumulatif-nilai"].join("/"),
    component: Nilai.DaftarKumulatifNilai,
  },
  {
    path: ["/nilai", "daftar-kumulatif-nilai", ":kelas_id"].join("/"),
    component: Nilai.DetilKumulatifNilai,
  },
  {
    path: ["/nilai", "daftar-kumulatif-nilai", "daftar-nilai", ":kbm_id"].join(
      "/",
    ),
    component: Nilai.DKNDaftarNilai,
  },
  {
    path: ["/nilai", "daftar-penilaian-hafalan"].join("/"),
    component: Nilai.DaftarNilaiHafalan,
  },
  {
    path: ["/nilai", "daftar-penilaian-hafalan", ":id"].join("/"),
    component: Nilai.DetilNilaiHafalan,
  },

  {
    path: ["/nilai", "daftar-peringkat"].join("/"),
    component: Nilai.DaftarPeringkat,
  },
  {
    path: ["/nilai", "ujian-sekolah"].join("/"),
    component: Nilai.DaftarNilaiUS,
  },
  {
    path: ["/nilai", "ujian-sekolah", ":kbm_id"].join("/"),
    component: Nilai.DetilNilaiUS,
  },
  {
    path: ["/nilai", "ujian-nasional"].join("/"),
    component: Nilai.DaftarNilaiUN,
  },
  {
    path: ["/nilai", "ujian-nasional", ":kbm_id"].join("/"),
    component: Nilai.DetilNilaiUN,
  },
  {
    path: ["/nilai", "daftar-kumulatif-usun"].join("/"),
    component: Nilai.DaftarKumulatifUSUN,
  },
  {
    path: ["/nilai", "daftar-kumulatif-usun", ":kelas_id"].join("/"),
    component: Nilai.DetilKumulatifUSUN,
  },
  {
    path: ["/nilai", "daftar-kumulatif-usun", ":kelas_id", ":kbm_id"].join("/"),
    component: Nilai.DetilKumulatifKBM,
  },

  // Nilai P5
  {
    path: ["/nilai", "p5"].join("/"),
    component: PenilaianP5.DaftarKelasNilaiP5,
  },
  {
    path: ["/nilai", "p5", ":kelompok_id"].join("/"),
    component: PenilaianP5.DetilInputNilaiP5,
  },
  // { path: ['/nilai', 'kelas', ':kelas_id', ''].join('/'), component: PenilaianP5.DaftarProyekNilaiP5 },

  {
    path: ["/pengaturan-sistem", "akun-karyawan"].join("/"),
    component: PengaturanSistem.AkunKaryawan,
  },
  {
    path: ["/pengaturan-sistem", "akun-karyawan", "tambah-karyawan"].join("/"),
    component: PengaturanSistem.TambahKaryawan,
  },
  {
    path: ["/pengaturan-sistem", "akun-karyawan", ":karyawan_id"].join("/"),
    component: PengaturanSistem.DetilProfilKaryawan,
  },
  {
    path: ["/pengaturan-sistem", "akun-murid"].join("/"),
    component: PengaturanSistem.AkunMurid,
  },
  {
    path: ["/pengaturan-sistem", "penguncian-akses"].join("/"),
    component: PengaturanSistem.PenguncianAkses,
  },
  {
    path: ["/pengaturan-sistem", "identitas-sekolah"].join("/"),
    component: PengaturanSistem.IdentitasSekolah,
  },
  {
    path: ["/pengaturan-sistem", "akun-murid", "tambah-murid"].join("/"),
    component: PengaturanSistem.TambahMurid,
  },
  {
    path: ["/pengaturan-sistem", "akun-murid", ":nis"].join("/"),
    component: PengaturanSistem.DetilProfilMurid,
  },
  {
    path: ["/pengaturan-sistem", "kepala-sekolah"].join("/"),
    component: PengaturanSistem.KepalaSekolah,
  },

  {
    path: ["/ekstrakurikuler", "daftar-nilai"].join("/"),
    component: Ekstrakurikuler.NilaiEkskul,
  },
  {
    path: ["/ekstrakurikuler", "daftar-nilai", ":_id"].join("/"),
    component: Ekstrakurikuler.DetilNilaiEkskul,
  },
  {
    path: ["/ekstrakurikuler", "daftar-ekskul"].join("/"),
    component: Ekstrakurikuler.DaftarEkskul,
  },
  {
    path: ["/ekstrakurikuler", "daftar-ekskul", "tambah-ekskul"].join("/"),
    component: Ekstrakurikuler.TambahDaftarEkskul,
  },
  {
    path: ["/ekstrakurikuler", "daftar-ekskul", ":_id"].join("/"),
    component: Ekstrakurikuler.DetilDaftarEkskul,
  },

  {
    path: ["/rapor", "pengaturan-rapor"].join("/"),
    component: Rapor.PengaturanRapor,
  },
  {
    path: ["/rapor", "cetak-sampul-rapor"].join("/"),
    component: Rapor.CetakSampulRapor,
  },
  {
    path: ["/rapor", "catatan-rapor"].join("/"),
    component: Rapor.InputCatatanRapor,
  },
  {
    path: ["/rapor", "catatan-rapor", ":id"].join("/"),
    component: Rapor.DetilCatatanRapor,
  },
  {
    path: ["/rapor", "rapor-hafalan"].join("/"),
    component: Rapor.CetakRaporHafalan,
  },
  {
    path: ["/rapor", "rapor-hafalan/:id"].join("/"),
    component: Rapor.DetilCetakRaporHafalan,
  },
  { path: ["/rapor", "cetak-rapor"].join("/"), component: Rapor.CetakRapor },
  {
    path: ["/rapor", "cetak-rapor/:id"].join("/"),
    component: Rapor.DetilCetakRapor,
  },
  { path: ["/rapor", "cetak-khs"].join("/"), component: Rapor.CetakKHS },
  {
    path: ["/rapor", "cetak-khs", ":id"].join("/"),
    component: Rapor.DetilCetakKHS,
  },
  {
    path: ["/rapor", "rekap-nsun"].join("/"),
    component: Rapor.DaftarRekapNSUN,
  },
  {
    path: ["/rapor", "rekap-nsun", "aa"].join("/"),
    component: Rapor.DetilRekapNSUN,
  },
  {
    path: ["/rapor", "rekap-nsun", "aa", "aa"].join("/"),
    component: Rapor.DetilRekapPerMurid,
  },
  {
    path: ["/rapor", "kelulusan"].join("/"),
    component: Rapor.DaftarDokumenKelulusan,
  },
  {
    path: ["/rapor", "kelulusan", ":kelas_id"].join("/"),
    component: Rapor.DetilDokumenKelulusan,
  },
  {
    path: ["/rapor", "rekap-rapor"].join("/"),
    component: Rapor.DaftarRekapRapor,
  },
  {
    path: ["/rapor", "rekap-rapor", ":kelas_id"].join("/"),
    component: Rapor.DetilRekapRapor,
  },
  {
    path: ["/rapor", "cetak-p5"].join("/"),
    component: PenilaianP5.DaftarRaporPenilaianP5,
  },
  {
    path: ["/rapor", "cetak-p5", ":kelas_id"].join("/"),
    component: PenilaianP5.DetilRaporPenilaianP5,
  },

  // { path: ['/rapor', 'rekap-rapor', 'aa', 'aa'].join('/'), component: Rapor.EditRekapPerUser },

  {
    path: ["/administrasi", "lembar-absensi"].join("/"),
    component: Administrasi.LembarAbsensi,
  },
  {
    path: ["/administrasi", "kartu-pelajar"].join("/"),
    component: Administrasi.KartuPelajar,
  },
  {
    path: ["/administrasi", "kuesioner"].join("/"),
    component: Administrasi.DaftarKuesioner,
  },
  {
    path: ["/administrasi", "kuesioner", "tambah"].join("/"),
    component: Administrasi.KuesionerBaru,
  },
  {
    path: ["/administrasi", "kuesioner", ":kuesioner_id"].join("/"),
    component: Administrasi.DetilKuesioner,
  },
  {
    path: ["/administrasi", "kuesioner", ":kuesioner_id", ":user_id"].join("/"),
    component: Administrasi.DetilKuesionerUser,
  },
  {
    path: ["/administrasi", "pengumuman"].join("/"),
    component: Administrasi.Pengumuman,
  },
  {
    path: ["/administrasi", "pengumuman", "tambah"].join("/"),
    component: Administrasi.TambahPengumuman,
  },
  {
    path: ["/administrasi", "pengumuman", ":id"].join("/"),
    component: Administrasi.DetilPengumuman,
  },

  {
    path: ["/konseling", "catatan"].join("/"),
    component: Konseling.CatatanKonseling,
  },
  {
    path: ["/konseling", "catatan", ":id"].join("/"),
    component: Konseling.DetilKonselingMurid,
  },
  {
    path: ["/konseling", "peminatan"].join("/"),
    component: Konseling.Peminatan,
  },
  {
    path: ["/konseling", "peminatan", ":id"].join("/"),
    component: Konseling.DetilPeminatan,
  },
  {
    path: ["/konseling", "rekap-peminatan"].join("/"),
    component: Konseling.RekapPeminatan,
  },
  {
    path: ["/konseling", "rekap-peminatan", "pengaturan-perguruan-tinggi"].join(
      "/",
    ),
    component: Konseling.PengaturanPerguruanTinggi,
  },
  {
    path: ["/konseling", "rekap-peminatan", ":id"].join("/"),
    component: Konseling.DetilRekapPeminatan,
  },

  {
    path: ["/murid", "biodata"].join("/"),
    component: Murid.DaftarBiodataMurid,
  },
  {
    path: ["/murid", "biodata", ":id"].join("/"),
    component: Murid.DetilBiodataMurid,
  },
  {
    path: ["/murid", "kelengkapan"].join("/"),
    component: Murid.KelengkapanMurid,
  },
  { path: ["/murid", "ikhtisar"].join("/"), component: Murid.IkhtisarMurid },
  {
    path: ["/murid", "ikhtisar", ":id"].join("/"),
    component: Murid.DetilIkhtisarMurid,
  },
  { path: ["/murid", "mutasi"].join("/"), component: Murid.CetakMutasi },
  {
    path: ["/murid", "mutasi", ":kelas_id"].join("/"),
    component: Murid.DetilCetakMutasi,
  },
  {
    path: ["/murid", "tinggal-kelas"].join("/"),
    component: Kurikulum.TinggalKelas,
  },
  {
    path: ["/murid", "penguncian-akses"].join("/"),
    component: Murid.PenguncianAksesMurid,
  },

  {
    path: ["/tata-tertib", "masukan"].join("/"),
    component: TataTertib.MasukanTatib,
  },
  {
    path: ["/tata-tertib", "rekapitulasi"].join("/"),
    component: TataTertib.RekapitulasiTatib,
  },
  {
    path: ["/tata-tertib", "rekapitulasi", ":nis"].join("/"),
    component: TataTertib.DetilTatib,
  },

  { path: ["/kelas", "daftar-kelas"].join("/"), component: Kelas.DaftarKelas },
  { path: ["/kelas", "kelas-baru"].join("/"), component: Kelas.KelasBaru },
  {
    path: ["/kelas", "daftar-kelas", ":id"].join("/"),
    component: Kelas.DetilDaftarKelas,
  },
  { path: ["/kelas", "kbm"].join("/"), component: Kelas.DaftarKBM },
  { path: ["/kelas", "kbm", ":id"].join("/"), component: Kelas.DetilKBM },
  {
    path: ["/kelas", "kelompok-hafalan"].join("/"),
    component: Kelas.DaftarKelompokHafalan,
  },
  {
    path: ["/kelas", "kelompok-hafalan", ":id"].join("/"),
    component: Kelas.DetilKelompokHafalan,
  },

  {
    path: ["/kelas", "kelompok-p5"].join("/"),
    component: Kelas.DaftarKelompokP5,
  },
  {
    path: ["/kelas", "kelompok-p5", ":kelas_id"].join("/"),
    component: Kelas.DetilKelompokP5,
  },
  {
    path: ["/kelas", "kelompok-p5", ":kelas_id", "tambah-kelompok"].join("/"),
    component: Kelas.TambahKelompokP5,
  },
  {
    path: ["/kelas", "kelompok-p5", ":kelas_id", ":kelompok_id"].join("/"),
    component: Kelas.KelasKelompokP5,
  },

  {
    path: ["/prestasi", "daftar-murid"].join("/"),
    component: Prestasi.DaftarPrestasiMurid,
  },
  {
    path: ["/prestasi", "daftar-murid", ":nis"].join("/"),
    component: Prestasi.DetilPrestasiMurid,
  },
  {
    path: ["/prestasi", "lomba"].join("/"),
    component: Prestasi.DaftarPrestasiLomba,
  },
  {
    path: ["/prestasi", "lomba", ":id"].join("/"),
    component: Prestasi.DetilPrestasiLomba,
  },
  {
    path: ["/prestasi", "organisasi"].join("/"),
    component: Prestasi.DaftarPrestasiOrganisasi,
  },
  {
    path: ["/prestasi", "organisasi", ":id"].join("/"),
    component: Prestasi.DetilPrestasiOrganisasi,
  },
  {
    path: ["/prestasi", "ekstrakurikuler"].join("/"),
    component: Prestasi.DaftarPrestasiEkstrakurikuler,
  },
  {
    path: ["/prestasi", "ekstrakurikuler", ":nis"].join("/"),
    component: Prestasi.DetilPrestasiEkstrakurikuler,
  },
  {
    path: ["/prestasi", "akademik"].join("/"),
    component: Prestasi.DaftarPrestasiAkademik,
  },
  {
    path: ["/prestasi", "akademik", ":nis"].join("/"),
    component: Prestasi.DetilPrestasiAkademik,
  },
  {
    path: ["/prestasi", "hafalan-surah"].join("/"),
    component: Prestasi.HafalanSurah,
  },
  {
    path: ["/prestasi", "hafalan-surah", "aa"].join("/"),
    component: Prestasi.DetilHafalan,
  },
  {
    path: ["/prestasi", "prestasi-lainnya"].join("/"),
    component: Prestasi.PrestasiLainnya,
  },
  {
    path: ["/prestasi", "prestasi-lainnya", ":id"].join("/"),
    component: Prestasi.DetilPrestasiLainnya,
  },

  {
    path: ["/ppdb", "calon-peserta"].join("/"),
    component: PPDB.DaftarCalonPeserta,
  },
  {
    path: ["/ppdb", "calon-peserta", ":no_peserta"].join("/"),
    component: PPDB.DetilCalonPeserta,
  },
  {
    path: ["/ppdb", "daftar-peserta"].join("/"),
    component: PPDB.DaftarPeserta,
  },
  {
    path: ["/ppdb", "daftar-peserta", ":no_peserta"].join("/"),
    component: PPDB.DetilDaftarPeserta,
  },
  { path: ["/ppdb", "input-nilai"].join("/"), component: PPDB.InputNilai },
  {
    path: ["/ppdb", "input-wawancara"].join("/"),
    component: PPDB.InputWawancara,
  },
  {
    path: ["/ppdb", "input-wawancara", ":no_peserta"].join("/"),
    component: PPDB.DetilWawancara,
  },
  { path: ["/ppdb", "rekap-nilai"].join("/"), component: PPDB.RekapNilai },
  { path: ["/ppdb", "hasil-ppdb"].join("/"), component: PPDB.HasilPPDB },
  { path: ["/ppdb", "pengaturan"].join("/"), component: PPDB.PengaturanPPDB },
];
