import _ from "lodash";

const CONTACT_IMPULS = "081228800048";

const JENJANG = "SMA";
const JENJANG_SEBELUMNYA = "SMP";
const NAMA_SEKOLAH = "DEFAULT_NAMA_SEKOLAH";
const JENJANG_SEBELUMNYA_KECIL = JENJANG_SEBELUMNYA.toLowerCase();

const LOGIN_FORM = "LOGIN_FORM";
const FORGET_FORM = "FORGET_FORM";
const DEFAULT_KKM = 70;
const DEFAULT_DIFF = 10;
const MAX_FILE_UPLOAD_SIZE = 5;
const BOBOT_NILAI_HAFALAN = {
  NILAI_KEHADIRAN: 25,
  NILAI_TILAWAH: 30,
  NILAI_SETORAN: 25,
  NILAI_UJIAN_TULIS: 20,
};

const MAP_LEVEL = { C: "create", R: "read", U: "update", D: "delete" };

const OPTS_JENIS_KELAMIN = "Laki-Laki Perempuan".split(" ").map((o) => ({
  key: _.snakeCase(o).toUpperCase(),
  value: _.first(o),
  text: o,
}));

const OPTS_JENJANG_PENDIDIKAN = "TK SD SMP SMA UNIVERSITAS"
  .split(" ")
  .map((o) => ({
    key: _.snakeCase(o).toUpperCase(),
    value: o,
    text: o,
  }));

const OPTS_WARNA = [
  { text: "Biru", value: "blue", key: "blue" },
  { text: "Hijau", value: "green", key: "green" },
  { text: "Kuning", value: "yellow", key: "yellow" },
  { text: "Merah", value: "red", key: "red" },
];
const OPTS_KEWARGANEGARAAN = [
  {
    key: "WNI",
    text: "Warga Negara Indonesia",
    value: "Warga Negara Indonesia",
  },
  { key: "WNA", text: "Warga Negara Asing", value: "Warga Negara Asing" },
];

const OPTS_YES_OR_NO = [
  { key: "yes", text: "Ya", value: true },
  { key: "no", text: "Tidak", value: false },
];

const OPTS_PENERANGAN_RUMAH = [
  { key: "Baik Sekali", value: "Baik Sekali", text: "Baik Sekali" },
  { key: "Baik", value: "Baik", text: "Baik" },
  { key: "Kurang", value: "Kurang", text: "Kurang" },
  { key: "Sangat Kurang", value: "Sangat Kurang", text: "Sangat Kurang" },
];

const OPTS_LINGKUNGAN_RUMAH = [
  { key: "Perumahan", value: "Perumahan", text: "Perumahan" },
  { key: "Perkantoran", value: "Dekat Perkantoran", text: "Dekat Perkantoran" },
  { key: "Jalan Raya", value: "Dekat Jalan Raya", text: "Dekat Jalan Raya" },
];

const OPTS_STATUS_RUMAH = [
  {
    key: "Orang Tua",
    value: "Rumah Pribadi/ bersama Orang Tua",
    text: "Rumah Pribadi/ bersama Orang Tua",
  },
  { key: "Kerabat", value: "Rumah Kerabat", text: "Rumah Kerabat" },
  { key: "Kos/ Kontrak", value: "Kos/ Kontrak", text: "Kos/ Kontrak" },
];

const OPTS_PENDIDIKAN = [
  { key: "SD", text: "SD", value: "SD" },
  { key: "SMP", text: "SMP", value: "SMP" },
  { key: "SMA", text: "SMA", value: "SMA" },
  { key: "D1", text: "D1", value: "D1" },
  { key: "D2", text: "D2", value: "D2" },
  { key: "D3", text: "D3", value: "D3" },
  { key: "S1", text: "S1", value: "S1" },
  { key: "S2", text: "S2", value: "S2" },
  { key: "S3", text: "S3", value: "S3" },
];

const OPTS_AGAMA = [
  { key: "Islam", text: "Islam", value: "ISLAM" },
  { key: "Kristen", text: "Kristen Protestan", value: "KRISTEN PROTESTAN" },
  { key: "Katholik", text: "Kristen Katholik", value: "KRISTEN KATHOLIK" },
  { key: "Hindu", text: "Hindu", value: "HINDU" },
  { key: "Buddha", text: "Buddha", value: "BUDDHA" },
  { key: "Konghucu", text: "Konghucu", value: "KONGHUCU" },
  { key: "Kepercayaan", text: "Kepercayaan", value: "KEPERCAYAAN" },
];

const OPTS_PERIODE = "SEMESTER INI-BULAN INI-MINGGU INI-HARI INI"
  .split("-")
  .map((o) => ({
    key: _.snakeCase(o).toUpperCase(),
    value: _.snakeCase(o).toUpperCase(),
    text: o.toLowerCase().split(" ").map(_.upperFirst).join(" "),
  }));

const OPTS_TINGKAT = [
  {
    key: "TINGKAT_SEKOLAH",
    value: "SEKOLAH",
    text: "Sekolah",
  },
  {
    key: "TINGKAT_KECAMATAN",
    value: "KECAMATAN",
    text: "Kecamatan",
  },
  {
    key: "TINGKAT_KABUPATENKOTA",
    value: "KABUPATENKOTA",
    text: "Kabupaten/Kota",
  },
  { key: "TINGKAT_PROVINSI", value: "PROVINSI", text: "Provinsi" },
  { key: "TINGKAT_NASIONAL", value: "NASIONAL", text: "Nasional" },
  {
    key: "TINGKAT_INTERNASIONAL",
    value: "INTERNASIONAL",
    text: "Internasional",
  },
];

const OPTS_JABATAN_EKTRAKURIKULER = [
  { key: "KETUA", value: "KETUA", text: "Ketua" },
  { key: "ANGGOTA", value: "ANGGOTA", text: "Anggota" },
];

const OPTS_KOMPETEN = [
  { key: "Amat Baik", value: "Amat Baik", text: "Amat Baik" },
  { key: "Baik", value: "Baik", text: "Baik" },
  { key: "Cukup", value: "Cukup", text: "Cukup" },
  { key: "Kurang", value: "Kurang", text: "Kurang" },
];

const OPTS_NAMA_ORGANISASI = [{ key: "ORG", value: "ORG", text: "ORG" }];

const OPTS_NILAI_PREDIKAT = [
  { key: "Kosong", value: null, text: "Kosong", content: "Kosong" },
  { key: "A", value: "A", text: "A", content: "A" },
  { key: "B", value: "B", text: "B", content: "B" },
  { key: "C", value: "C", text: "C", content: "C" },
  { key: "D", value: "D", text: "D", content: "D" },
  { key: "E", value: "E", text: "E", content: "E" },
];

export const PRESTASI_LAINNYA = "PRESTASI_LAINNYA";

const OPTS_JENIS_PRESTASI = [
  { key: "PRESTASI_LOMBA", value: "LOMBA", text: "Lomba" },
  { key: "PRESTASI_ORGANISASI", value: "ORGANISASI", text: "Organisasi" },
  {
    key: "PRESTASI_EKSTRAKURIKULER",
    value: "EKSTRAKURIKULER",
    text: "Ekstrakurikuler",
  },
  { key: "PRESTASI_AKADEMIK", value: "AKADEMIK", text: "Akademik" },
  {
    key: PRESTASI_LAINNYA,
    value: PRESTASI_LAINNYA,
    text: "Prestasi Lainnya",
  },
];

const OPTS_TUJUAN_PENGUMUMAN = [
  { key: "murid", value: "murid", text: "Murid" },
  { key: "wali_murid", value: "wali_murid", text: "Wali Murid" },
  { key: "karyawan", value: "karyawan", text: "Karyawan" },
];
const OPTS_ORTU = [
  { key: "Ayah", value: "ayah", text: "Ayah" },
  { key: "Ibu", value: "ibu", text: "Ibu" },
  { key: "Wali", value: "wali", text: "Wali" },
];

const OPTS_TIPE_NILAI_PENGETAHUAN = [
  // { key: "TLS", value: "TLS", text: "TLS", content: "Tulis" },
  // { key: "LSN", value: "LSN", text: "LSN", content: "Lisan" },
  { key: "TGS", value: "TGS", text: "TGS", content: "Tugas" },
  { key: "ULG", value: "ULG", text: "ULG", content: "Ulangan" },
];

const OPTS_TIPE_NILAI_SIKAP = [
  { key: "OBS", value: "OBSERVASI", text: "Observasi", content: "Observasi" },
  { key: "DRI", value: "DIRI", text: "Diri", content: "Diri" },
  { key: "ATR", value: "ANTAR TEMAN", text: "Teman", content: "Antar Teman" },
  { key: "JRL", value: "JURNAL", text: "Jurnal", content: "Jurnal" },
];

const OPTS_TIPE_NILAI_KETERAMPILAN = [
  { key: "PTK", value: "PTK", text: "PTK", content: "Praktik" },
  { key: "PRD", value: "PRD", text: "PRD", content: "Produk" },
  { key: "PRT", value: "PRT", text: "PRT", content: "Portofolio" },
  { key: "PRY", value: "PRY", text: "PRY", content: "Proyek" },
];

const OPTS_JALUR_MASUK = [
  { key: "PRESTASI", value: "PRESTASI", text: "Prestasi" },
  { key: "RAPOR", value: "RAPOR", text: "Rapor" },
  { key: "REGULER", value: "REGULER", text: "Reguler" },
  { key: "MANDIRI", value: "MANDIRI", text: "Mandiri" },
];

const OPTS_PERNYATAAN = [
  {
    key: "Iya, Saya setuju",
    value: "Iya, Saya setuju",
    text: "Iya, Saya setuju",
  },
  {
    key: "Saya ingin konfirmasi lebih lanjut",
    value: "Saya ingin konfirmasi lebih lanjut",
    text: "Saya ingin konfirmasi lebih lanjut",
  },
];

const OPTS_NILAI_P5 = [
  { key: "SAB", value: "SAB", text: "SAB", content: "Sangat Berkembang" },
  {
    key: "BSH",
    value: "BSH",
    text: "BSH",
    content: "Berkembang Sesuai Harapan",
  },
  { key: "SB", value: "SB", text: "SB", content: "Sedang Berkembang" },
  { key: "MB", value: "MB", text: "MB", content: "Mulai Berkembang" },
];

const OPTS_COLOR = [
  "purple",
  "blue",
  "teal",
  "green",
  "yellow",
  "orange",
  "red",
];

const OPTS_KELAS_TINGKAT = "T1 T2 T3"
  .split(" ")
  .map((v) => ({ key: v, text: `Tingkat ${v}`, value: v }));
const OPTS_KELAS_PROGRAM = "P1 P2"
  .split(" ")
  .map((v) => ({ key: v, text: v, value: v }));

const IMAGE_SMALL = 100;
const IMAGE_MEDIUM = 150;
const IMAGE_HIGH = 300;

const POIN_LIMIT = 0;
const BATAS_POIN_PELANGGARAN = 500;
const OPTS_TATIB_WAKTU = OPTS_PERIODE;

const KURIKULUM_2013 = "K2013";
const KURIKULUM_MERDEKA = "MERDEKA";
const KURIKULUM_2013_PANJANG = "K2013";
const KURIKULUM_MERDEKA_PANJANG = "MERDEKA";

const MIME_DROPZONE_PDF = { "application/pdf": [] };
const MIME_DROPZONE_IMAGE = { "image/jpeg": [], "image/png": [] };
const MIME_DROPZONE_EXCEL = {
  "application/vnd.ms-excel": [],
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [],
};

const TABLE_MIN_ROW = 5;

const OPTIONS = {
  OPTS_YES_OR_NO,
  OPTS_WARNA,
  OPTS_COLOR,
  OPTS_AGAMA,
  OPTS_TINGKAT,
  OPTS_PERIODE,
  OPTS_PENDIDIKAN,
  OPTS_TATIB_WAKTU,
  OPTS_JENIS_PRESTASI,
  OPTS_JENIS_KELAMIN,
  OPTS_JENJANG_PENDIDIKAN,
  OPTS_NAMA_ORGANISASI,
  OPTS_PENERANGAN_RUMAH,
  OPTS_JABATAN_EKTRAKURIKULER,
  OPTS_TIPE_NILAI_PENGETAHUAN,
  OPTS_TIPE_NILAI_SIKAP,
  OPTS_KEWARGANEGARAAN,
  OPTS_TIPE_NILAI_KETERAMPILAN,
  OPTS_NILAI_PREDIKAT,
  OPTS_KELAS_TINGKAT,
  OPTS_STATUS_RUMAH,
  OPTS_LINGKUNGAN_RUMAH,
  OPTS_KELAS_PROGRAM,
  OPTS_JALUR_MASUK,
  OPTS_TUJUAN_PENGUMUMAN,
  OPTS_ORTU,
  OPTS_PERNYATAAN,
  OPTS_KOMPETEN,
  OPTS_NILAI_P5,
};

const PPDB_TAHUN = "2025/2026";
const PPDB_JUMLAH_NILAI = 12;

const PPDB_BIAYA_SERAGAM_PA = 1450000;
const PPDB_BIAYA_SERAGAM_PI = 1650000;
const PPDB_BIAYA_WISMA = 5700000;
const PPDB_BIAYA_OPERASIONAL = 1400000;
const PPDB_BIAYA_CATERING = 250000;
const PPDB_BIAYA_CATERING_WISMA = 855000;
const PPDB_BIAYA_INFAQ_TUNAI = 16500000;
const PPDB_BIAYA_INFAQ_ANGSURAN = 17500000;

export {
  NAMA_SEKOLAH,
  CONTACT_IMPULS,
  OPTIONS,
  POIN_LIMIT,
  BATAS_POIN_PELANGGARAN,
  LOGIN_FORM,
  FORGET_FORM,
  DEFAULT_KKM,
  DEFAULT_DIFF,
  MAP_LEVEL,
  MAX_FILE_UPLOAD_SIZE,
  IMAGE_SMALL,
  IMAGE_MEDIUM,
  IMAGE_HIGH,
  JENJANG,
  KURIKULUM_2013,
  KURIKULUM_MERDEKA,
  KURIKULUM_2013_PANJANG,
  KURIKULUM_MERDEKA_PANJANG,
  JENJANG_SEBELUMNYA,
  JENJANG_SEBELUMNYA_KECIL,
  BOBOT_NILAI_HAFALAN,
  MIME_DROPZONE_PDF,
  MIME_DROPZONE_IMAGE,
  MIME_DROPZONE_EXCEL,
  TABLE_MIN_ROW,

  /** PPDB CONSTANT */
  PPDB_TAHUN,
  PPDB_JUMLAH_NILAI,
  PPDB_BIAYA_CATERING,
  PPDB_BIAYA_CATERING_WISMA,
  PPDB_BIAYA_WISMA,
  PPDB_BIAYA_OPERASIONAL,
  PPDB_BIAYA_SERAGAM_PA,
  PPDB_BIAYA_SERAGAM_PI,
  PPDB_BIAYA_INFAQ_TUNAI,
  PPDB_BIAYA_INFAQ_ANGSURAN,
};
