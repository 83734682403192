export const generateListMenu = ({
  nama = "",
  handleLogout = () => "",
} = {}) => {
  const list = [
    /**
     * KARYAWAN
     */
    {
      id: "PENGUMUMAN",
      logo: "imp imp-pengumuman",
      menu: "Pengumuman",
      to: "/pengumuman",
    },

    { id: "NILAI", logo: "imp imp-nilai", menu: "Nilai" },
    {
      parent: "NILAI",
      id: "NILAI#DKELAS_MAPEL",
      menu: "Daftar Nilai",
      to: "/nilai/daftar-nilai",
    },
    // { parent: "NILAI", id: "NILAI#DKELAS_ABSENSI", menu: "Absensi", to: "/nilai/absensi" },
    {
      parent: "NILAI",
      id: "NILAI#DKELAS_DKN",
      menu: "Daftar Kumulatif Nilai",
      to: "/nilai/daftar-kumulatif-nilai",
    },
    {
      parent: "NILAI",
      id: "NILAI#DKEL_HAFALAN",
      menu: "Daftar Nilai Hafalan",
      to: "/nilai/daftar-penilaian-hafalan",
    },
    {
      parent: "NILAI",
      id: "NILAI#PERINGKAT",
      menu: "Daftar Peringkat",
      to: "/nilai/daftar-peringkat",
    },
    {
      parent: "NILAI",
      id: "NILAI#DKELAS_MAPEL_US",
      menu: "Nilai Ujian Sekolah",
      to: "/nilai/ujian-sekolah",
    },
    // { parent: "NILAI", id: "NILAI#DKELAS_UN", menu: "Nilai Ujian Nasional", to: "/nilai/ujian-nasional" },
    {
      parent: "NILAI",
      id: "NILAI#DKELAS_KUSUN",
      menu: "Daftar Kumulatif US",
      to: "/nilai/daftar-kumulatif-usun",
    },
    {
      parent: "NILAI",
      id: "NILAI#DKELOMPOK_INPUT_P5",
      menu: "Input Nilai P5",
      to: "/nilai/p5",
    },

    { id: "RAPOR", logo: "imp imp-rapor", menu: "Rapor" },
    {
      parent: "RAPOR",
      id: "RAPOR#DKELAS_INPUTCATATAN",
      menu: "Input Catatan Rapor",
      to: "/rapor/catatan-rapor",
    },
    {
      parent: "RAPOR",
      id: "RAPOR#DKELAS_CETAKSAMPUL",
      menu: "Cetak Sampul Rapor",
      to: "/rapor/cetak-sampul-rapor",
    },
    // { parent: "RAPOR", id: "RAPOR#DKELAS_KHS", menu: "Cetak KHS", to: "/rapor/cetak-khs" },
    {
      parent: "RAPOR",
      id: "RAPOR#DKELAS_CETAKRAPOR",
      menu: "Cetak Rapor",
      to: "/rapor/cetak-rapor",
    },
    // { parent: "RAPOR", id: "RAPOR#DKELAS_REKAP_NSUN", menu: "Rekap Nilai Ujian Sekolah", to: "/rapor/rekap-nsun" },
    {
      parent: "RAPOR",
      id: "RAPOR#DKELAS_P5",
      menu: "Cetak Rapor P5",
      to: "/rapor/cetak-p5",
    },
    {
      parent: "RAPOR",
      id: "RAPOR#DKEL_CETAKRAPORHAFALAN",
      menu: "Cetak Rapor Hafalan",
      to: "/rapor/rapor-hafalan",
    },
    {
      parent: "RAPOR",
      id: "RAPOR#DKELAS_KELULUSAN",
      menu: "Dokumen Kelulusan",
      to: "/rapor/kelulusan",
    },
    {
      parent: "RAPOR",
      id: "RAPOR#DKELAS_REKAP_NR",
      menu: "Rekap Nilai Rapor",
      to: "/rapor/rekap-rapor",
    },
    {
      parent: "RAPOR",
      id: "RAPOR#DKELAS_REKAP_NSUN",
      menu: "Rekap Nilai Ujian Sekolah",
      to: "/rapor/rekap-nsun",
    },
    {
      parent: "RAPOR",
      id: "RAPOR#PENGATURAN",
      menu: "Pengaturan Rapor",
      to: "/rapor/pengaturan-rapor",
    },

    { id: "MURID-RAPOR", logo: "imp imp-rapor", menu: "Rapor", to: "/rapor" },

    { id: "KURIKULUM_K13", logo: "imp imp-kurikulum", menu: "Kurikulum 2013" },
    {
      parent: "KURIKULUM_K13",
      id: "KURIKULUM_K13#DMAPEL",
      menu: "Daftar Mapel & KD",
      to: "/kurikulum-k13/daftar-mapel",
    },
    // { parent: "KURIKULUM_K13", id: "KURIKULUM_K13#BEBAN", menu: "Beban Mata Pelajaran", to: "/kurikulum-k13/beban-mapel" },
    {
      parent: "KURIKULUM_K13",
      id: "KURIKULUM_K13#SIKAP",
      menu: "Parameter Sikap",
      to: "/kurikulum-k13/parameter-sikap",
    },

    {
      id: "KURIKULUM_MERDEKA",
      logo: "imp imp-kurikulum",
      menu: "Kurikulum Merdeka",
    },
    {
      parent: "KURIKULUM_MERDEKA",
      id: "KURIKULUM_MERDEKA#DMAPEL",
      menu: "Daftar Mapel & TP",
      to: "/kurikulum-merdeka/daftar-mapel",
    },
    {
      parent: "KURIKULUM_MERDEKA",
      id: "KURIKULUM_MERDEKA#DPROYEK",
      menu: "Daftar Proyek P5",
      to: "/kurikulum-merdeka/proyek-p5",
    },
    {
      parent: "KURIKULUM_MERDEKA",
      id: "KURIKULUM_MERDEKA#PENGATURAN_P5",
      menu: "Pengaturan P5",
      to: "/kurikulum-merdeka/pengaturan-p5",
    },

    { id: "EKSKUL", logo: "imp imp-ekskul", menu: "Ekstrakurikuler" },
    {
      parent: "EKSKUL",
      id: "EKSKUL#DEKSKUL_NILAI",
      menu: "Nilai Ekskul",
      to: "/ekstrakurikuler/daftar-nilai",
    },
    {
      parent: "EKSKUL",
      id: "EKSKUL#DEKSKUL",
      menu: "Daftar Ekskul",
      to: "/ekstrakurikuler/daftar-ekskul",
    },

    { id: "PRESTASI", logo: "imp imp-prestasi", menu: "Prestasi" },
    {
      parent: "PRESTASI",
      id: "PRESTASI#DAFTARPRESTASI",
      menu: "Murid Berprestasi",
      to: "/prestasi/daftar-murid",
    },
    {
      parent: "PRESTASI",
      id: "PRESTASI#DAFTARLOMBA",
      menu: "Lomba",
      to: "/prestasi/lomba",
    },
    {
      parent: "PRESTASI",
      id: "PRESTASI#DAFTARORGANISASI",
      menu: "Keorganisasian",
      to: "/prestasi/organisasi",
    },
    {
      parent: "PRESTASI",
      id: "PRESTASI#DAFTAREKSKUL",
      menu: "Ekstrakurikuler",
      to: "/prestasi/ekstrakurikuler",
    },
    {
      parent: "PRESTASI",
      id: "PRESTASI#DAFTARAKADEMIK",
      menu: "Akademik",
      to: "/prestasi/akademik",
    },
    {
      parent: "PRESTASI",
      id: "PRESTASI#DPRESTASILAIN",
      menu: "Prestasi Lainnya",
      to: "/prestasi/prestasi-lainnya",
    },

    { id: "TATATERTIB", logo: "imp imp-tata-tertib", menu: "Tata Tertib" },
    {
      parent: "TATATERTIB",
      id: "TATATERTIB#INPUT",
      menu: "Input Tata Tertib",
      to: "/tata-tertib/masukan",
    },
    {
      parent: "TATATERTIB",
      id: "TATATERTIB#DAFTAR",
      menu: "Rekapitulasi Tata Tertib",
      to: "/tata-tertib/rekapitulasi",
    },

    { id: "KONSELING", logo: "imp imp-konseling", menu: "Bimbingan Konseling" },
    {
      parent: "KONSELING",
      id: "KONSELING#DKELAS_CATATAN",
      menu: "Catatan Konseling",
      to: "/konseling/catatan",
    },
    {
      parent: "KONSELING",
      id: "KONSELING#DKELAS_PEMINATAN",
      menu: "Peminatan",
      to: "/konseling/peminatan",
    },
    {
      parent: "KONSELING",
      id: "KONSELING#REKAP_PEMINATAN",
      menu: "Rekap Peminatan",
      to: "/konseling/rekap-peminatan",
    },

    { id: "PPDB", logo: "imp imp-kelas-pengaturan", menu: "PPDB & Peminatan" },
    {
      parent: "PPDB",
      id: "PPDB#CALONPESERTA",
      menu: "Daftar Calon Peserta",
      to: "/ppdb/calon-peserta",
    },
    {
      parent: "PPDB",
      id: "PPDB#DAFTARPESERTA",
      menu: "Daftar Peserta",
      to: "/ppdb/daftar-peserta",
    },
    {
      parent: "PPDB",
      id: "PPDB#NILAITES",
      menu: "Input Nilai PPDB",
      to: "/ppdb/input-nilai",
    },
    {
      parent: "PPDB",
      id: "PPDB#NILAIWAWANCARA",
      menu: "Input Nilai Wawancara",
      to: "/ppdb/input-wawancara",
    },
    {
      parent: "PPDB",
      id: "PPDB#REKAP",
      menu: "Rekap Nilai & Peringkat",
      to: "/ppdb/rekap-nilai",
    },
    {
      parent: "PPDB",
      id: "PPDB#HASIL",
      menu: "Hasil PPDB",
      to: "/ppdb/hasil-ppdb",
    },
    {
      parent: "PPDB",
      id: "PPDB#PENGATURAN",
      menu: "Pengaturan PPDB",
      to: "/ppdb/pengaturan",
    },

    { id: "MURID", logo: "imp imp-peserta", menu: "Murid" },
    {
      parent: "MURID",
      id: "MURID#DMURID_BIO",
      menu: "Biodata Murid",
      to: "/murid/biodata",
    },
    {
      parent: "MURID",
      id: "MURID#DMURID_KELENGKAPAN",
      menu: "Kelengkapan Murid",
      to: "/murid/kelengkapan",
    },
    {
      parent: "MURID",
      id: "MURID#DKELAS_IKHTISAR",
      menu: "Ikhtisar Murid",
      to: "/murid/ikhtisar",
    },
    {
      parent: "MURID",
      id: "MURID#DKELAS_MUTASI",
      menu: "Dokumen Mutasi",
      to: "/murid/mutasi",
    },
    {
      parent: "MURID",
      id: "MURID#TINGGAL",
      menu: "Daftar Tinggal Kelas",
      to: "/murid/tinggal-kelas",
    },
    {
      parent: "MURID",
      id: "MURID#PENGUNCIAN_AKSES",
      menu: "Penguncian Akses",
      to: "/murid/penguncian-akses",
    },

    {
      id: "PPDB-PESERTA-PENGUMUMAN",
      logo: "imp imp-pengumuman",
      menu: "Informasi PPDB",
      to: "/ppdb/pengumuman",
    },
    { id: "PPDB-PESERTA", logo: "imp imp-ppdb", menu: "Data PPDB" },
    {
      parent: "PPDB-PESERTA",
      id: "PPDB-PESERTA#DATA",
      menu: "Data Peserta",
      to: "/ppdb/data",
    },

    { id: "KELAS", logo: "imp imp-kelompok", menu: "Kelas & Kelompok" },
    {
      parent: "KELAS",
      id: "KELAS#DKELAS_KELAS",
      menu: "Daftar Kelas",
      to: "/kelas/daftar-kelas",
    },
    {
      parent: "KELAS",
      id: "KELAS#DKELAS_KBM",
      menu: "Daftar KBM",
      to: "/kelas/kbm",
    },
    {
      parent: "KELAS",
      id: "KELAS#DKELAS_KELOMPOK",
      menu: "Daftar Kelompok Hafalan",
      to: "/kelas/kelompok-hafalan",
    },
    {
      parent: "KELAS",
      id: "KELAS#DKELAS_P5",
      menu: "Daftar Kelas P5",
      to: "/kelas/kelompok-p5",
    },

    { id: "ADMINISTRASI", logo: "imp imp-administrasi", menu: "Administrasi" },
    {
      parent: "ADMINISTRASI",
      id: "ADMINISTRASI#DKELAS_CETAKABSENSI",
      menu: "Lembar Absensi",
      to: "/administrasi/lembar-absensi",
    },
    {
      parent: "ADMINISTRASI",
      id: "ADMINISTRASI#DKUESIONER",
      menu: "Kuesioner",
      to: "/administrasi/kuesioner",
    },
    {
      parent: "ADMINISTRASI",
      id: "ADMINISTRASI#PENGUMUMAN",
      menu: "Pengumuman",
      to: "/administrasi/pengumuman",
    },
    {
      parent: "ADMINISTRASI",
      id: "ADMINISTRASI#DKELAS_KARTUPELAJAR",
      menu: "Kartu Pelajar",
      to: "/administrasi/kartu-pelajar",
    },

    { id: "SISTEM", logo: "imp imp-pengaturan", menu: "Pengaturan Sistem" },
    {
      parent: "SISTEM",
      id: "SISTEM#IDSEKOLAH",
      menu: "Identitas Sekolah",
      to: "/pengaturan-sistem/identitas-sekolah",
    },
    {
      parent: "SISTEM",
      id: "SISTEM#KEPALASEKOLAH",
      menu: "Kepala Sekolah & Wakil",
      to: "/pengaturan-sistem/kepala-sekolah",
    },
    {
      parent: "SISTEM",
      id: "SISTEM#DKARYAWAN",
      menu: "Akun Karyawan",
      to: "/pengaturan-sistem/akun-karyawan",
    },
    {
      parent: "SISTEM",
      id: "SISTEM#DMURID",
      menu: "Akun Murid & Wali Murid",
      to: "/pengaturan-sistem/akun-murid",
    },
    {
      parent: "SISTEM",
      id: "SISTEM#KUNCI",
      menu: "Penguncian Akses",
      to: "/pengaturan-sistem/penguncian-akses",
    },
    //========================================================================

    /**
     * MURID
     */

    { id: "IKHTISAR", logo: "imp imp-ikhtisar", menu: "Ikhtisar" },
    {
      parent: "IKHTISAR",
      id: "IKHTISAR#UMUM",
      menu: "Ikhtisar Umum",
      to: "/ikhtisar/umum",
    },
    {
      parent: "IKHTISAR",
      id: "IKHTISAR#NILAIRAPOR",
      menu: "Nilai Rapor",
      to: "/ikhtisar/nilai-rapor",
    },
    {
      parent: "IKHTISAR",
      id: "IKHTISAR#AKADEMIK",
      menu: "Ikhtisar Akademik",
      to: "/ikhtisar/akademik",
    },
    {
      parent: "IKHTISAR",
      id: "IKHTISAR#KESISWAAN",
      menu: "Ikhtisar Kesiswaan",
      to: "/ikhtisar/kesiswaan",
    },
    {
      parent: "IKHTISAR",
      id: "IKHTISAR#TATATERTIB",
      menu: "Ikhtisar Tata Tertib",
      to: "/ikhtisar/tata-tertib",
    },
    // { parent: "IKHTISAR", id: "IKHTISAR#KEUANGAN", menu: "Ikhtisar Keuangan", to: "/ikhtisar/keuangan" },

    { id: "FORM", logo: "imp imp-administrasi", menu: "Formulir & Kuesioner" },
    {
      parent: "FORM",
      id: "FORM#DAFTARULANG",
      menu: "Daftar Ulang",
      to: "/daftar-ulang",
    },
    {
      parent: "FORM",
      id: "FORM#PEMINATAN",
      menu: "Peminatan",
      to: "/peminatan",
    },
    {
      parent: "FORM",
      id: "FORM#DKUESIONER",
      menu: "Kuesioner",
      to: "/kuesioner",
    },
    //========================================================================

    /**
     * Generic
     */
    { id: "PROFIL", logo: "imp imp-profil", menu: nama },
    {
      parent: "PROFIL",
      id: "PROFIL#ATUR#PPDB",
      menu: "Pengaturan Profil",
      to: "/ppdb/profil",
    },
    {
      parent: "PROFIL",
      id: "PROFIL#ATUR#MURID",
      menu: "Pengaturan Profil",
      to: "/profil",
    },
    {
      parent: "PROFIL",
      id: "PROFIL#ATUR#KARYAWAN",
      menu: "Pengaturan Profil",
      to: "/karyawan/profil",
    },
    {
      parent: "PROFIL",
      id: "PROFIL#ATUR#WALIMURID",
      menu: "Pengaturan Profil",
      to: "/profil",
    },
    {
      parent: "PROFIL",
      id: "PROFIL#KELUAR",
      menu: "Keluar / Log Out",
      onClick: handleLogout,
    },
    //========================================================================
  ];
  return list;
};
